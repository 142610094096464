import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    button: {
        textTransform: 'none',
        fontFamily: 'Visby',
        fontSize: 16,
        padding: '0 8px'
    },
}));
