import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentBusNumber} from "../../../../actions/recharge";
import {Input} from "../../../../styles";
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    input: {
        "& .Mui-error": {
            color: '#f44336'
        },
        "& .MuiFormHelperText-root": {
            color: '#197D8F'
        }
    },
}));

export const InputNumber = ({error}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const card = useSelector(state => state?.recharge?.bus_card);

    const handleChangeNumber = (value) => {

        if (value?.length <= 16) {
            dispatch(setCurrentBusNumber(value));
        }

    }

    return (
        <Input
            focused={error?.number}
            style={{marginBottom: 28}}
            id="outlined-number"
            label="Número de tarjeta"
            type="number"
            InputLabelProps={{shrink: true}}
            InputProps={{
                className: classes.input,
            }}
            variant="outlined"
            value={card?.number}
            onChange={(e) => handleChangeNumber(e.target.value)}
            placeholder={'0000 0000 0000 0000'}
            required
            error={error?.number}
            helperText={error.number && 'Revisa este dato'}
            onPaste={(e) => handleChangeNumber(e.target.value)}
        />
    )
}
