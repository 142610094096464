import React, {useEffect, useState} from "react";
import {
    CardType,
    CheckIcon,
    Container,
    DownloadPayment, ErrorIcon,
    IconContainer,
    Info,
    InformationContainer,
    Item,
    ItemContent,
    ItemImage,
    ItemName,
    Items,
    PaymentIcon,
    SelectAnotherCard,
    Title,
    ViewTerminals
} from "./styles";
import PDF from "../../../../img/movypay/pdf_icon.png";
import {useStyles} from "./material";
import {useDispatch, useSelector} from "react-redux";
import {
    debitTypes,
    getRechargeError,
    setCurrentCard,
    setRechargeTerminals,
    setUserCards
} from "../../../../actions/recharge";
import {useHistory} from "react-router-dom";
import {logEvent} from "../../../../firebase/firebase-config";
import useQuery from "../../../../hooks/useQuery";
import moment from "moment";
import {getUserRechargeCards} from "../../../../db/movypay/getUserRechargeCards";
import {jsPDF} from 'jspdf';
import html2canvas from "html2canvas";
import {Payment} from "../../../../uiComponents/movypay/Payment";
import {LoadingContainer} from "../Amount/styles";
import {CircularProgress} from "@material-ui/core";
import {getLocalTransaction, setLocalTransaction} from "../../../../db/movypay/localData";
import {getNearbyTerminals} from "../../../../db/movypay/getNearbyTerminals";

export const Download = () => {

    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();

    const classes = useStyles();

    const cityConfig = useSelector(state => state?.ui?.cityConfig);
    const uid = useSelector(state => state?.auth?.uid);
    const card = useSelector(state => state?.recharge?.bus_card);
    const userCards = useSelector(state => state?.recharge?.user_cards);
    const company = useSelector(state => state?.recharge?.company);

    const [params, setParams] = useState();
    const paymentCard = debitTypes?.find((type) => type?.id === parseInt(query.get('mp')))

    useEffect(() => {

        if (!params) {

            setParams({
                card_id: query.get('urci') ? parseInt(query.get('urci')) : undefined,
                status: query.get('status').replaceAll("'", ""),
                recharge_service_id: query.get('rsi'),
                recharge_service_name: query.get('co'),
                recharge_service_description: query.get('dc'),
                recharge_amount: query.get('mo'),
                type_payment_card: query.get('mp'),
                last_debit_card_number: query.get('u4'),
                operation_number: query.get('op'),
                error_message: decodeURI(query.get('em')),
                error_id: parseInt(query.get('ei')),
                recharge_transaction_id: query.get('rti') && parseInt(query.get('rti')) > 0 ? parseInt(query.get('rti')) : undefined,
                recharge_timestamp: parseInt(query.get('rt'))
            });

        }

    }, []);

    useEffect(() => {

        if (userCards || !card) {

            const current = userCards?.find(card => parseInt(card.id) === parseInt(params?.card_id));
            dispatch(setCurrentCard(current));

            getLocalTransaction()
                .then((response) => {

                    if (response === null || Number(response) !== Number(query.get('rti'))) {
                        logEvent('recharge_card_transaction', {
                            card_name: current?.name, // String
                            card_number: current?.card_ref_id, // String
                            card_id: current?.id, // Int
                            status: query.get('status'), // String
                            amount_selected: params?.recharge_amount?.toString(), // String
                            user_id: uid, // String
                            card_company_name: current?.recharge_services?.name, // String
                            recharge_transaction_id: params?.recharge_transaction_id, // Int
                            city_id: cityConfig?.city_id // Int
                        });
                    }

                    setLocalTransaction(params?.recharge_transaction_id);
                })
        }

    }, [userCards, card]);

    const handleViewTerminals = async () => {

        const reqBody = {recharge_service_id: parseInt(params?.recharge_service_id)}
        const terminals = await getNearbyTerminals(reqBody);
        dispatch(setRechargeTerminals(terminals));

        logEvent('recharge_card_show_nearby_terminals', {
            card_name: card?.name, // String
            card_number: card?.card_ref_id, // String
            card_id: card?.id, // Int
            status: params?.status, // String
            amount_selected: params?.recharge_amount, // String
            user_id: uid, // String
            card_company_name: company?.name, // String
            recharge_transaction_id: params?.recharge_transaction_id, // String
            city_id: cityConfig?.city_id // String
        })

        history.push(`/card-recharge/terminals?recharge_service_id=${params?.recharge_service_id}&custom_token=${query.get('custom_token') || null}`);
    };

    const handleSelectAnotherBusCard = async () => {

        const cards = await getUserRechargeCards(card?.recharge_services?.id);

        if (!userCards) {
            dispatch(setUserCards(cards));
        }

        if (cards?.length === 1) {
            history.push(`/card-recharge/card-data?recharge_service_id=${params?.recharge_service_id}&custom_token=${query.get('custom_token') || null}`);
        } else if (cards?.length >= 2) {
            history.push(`/card-recharge/user-cards?recharge_service_id=${params?.recharge_service_id}&custom_token=${query.get('custom_token') || null}`);
        }

    };

    const items = [
        {
            name: `Recarga ${company?.name}`,
            content: card?.card_ref_id,
            color: '#8C8C8C',
            enabled: true
        },
        {
            name: 'Medio de pago',
            content: `**** **** **** ${params?.last_debit_card_number}`,
            color: '#8C8C8C',
            src: paymentCard?.icon,
            enabled: params?.last_debit_card_number
        },
        {
            name: 'Fecha y hora',
            content: moment(params?.recharge_timestamp).format('DD/MM/YYYY HH:mm:ss A'),
            color: '#5DCD8E',
            checked: true,
            enabled: params?.status === 'ok'
        },
        {
            name: 'Pagaste',
            content: `$${params?.recharge_amount}.00`,
            color: '#5DCD8E',
            checked: true,
            enabled: params?.status === 'ok'
        },
        {
            name: 'Nro. de operación',
            content: params?.operation_number,
            color: '#8C8C8C',
            enabled: params?.status === 'ok'
        },
        {
            name: `Nro. de transacción de ${company?.name}`,
            content: params?.recharge_transaction_id,
            color: '#8C8C8C',
            enabled: params?.recharge_transaction_id && params?.recharge_transaction_id > 0
        },
    ];

    const printPDF = async () => {

        logEvent('card_recharge_card_download_payment', {
            card_number: card?.card_ref_id,
            card_name: card?.name,
            payment_type: null,
            date_and_time: null,
            amount_selected: params?.recharge_amount,
            transaction_number: params?.recharge_transaction_id,
            operation_number: null,
            card_company_name: card?.recharge_services?.name, // String
            card_id: card?.id,
            debit_card_expiration_date: null,
            debit_card_number: null,
            debit_type: paymentCard?.name,
            city_id: cityConfig?.city_id,
            user_id: uid
        });

        const domElement = document.getElementById("page");

        const canvas = await html2canvas(domElement, {
            onclone: (document) => {
                document.getElementById("component").style.visibility = "hidden";
                document.getElementById('page').style.visibility = 'visible';
            }
        });

        const imgData = canvas.toDataURL('image/png');

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("p", "mm", "a4");

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`Comprobante - Nro. ${params?.recharge_transaction_id}.pdf`);

    };

    const selectAnother = () => {

        dispatch(setCurrentCard({name: '', number: ''}));

        if (userCards?.length === 1) {
            history.push(`/card-recharge/card-data?recharge_service_id=${params?.recharge_service_id}&custom_token=${query.get('custom_token') || null}`);
        } else if (userCards?.length >= 2) {
            history.push(`/card-recharge/user-cards?recharge_service_id=${params?.recharge_service_id}&custom_token=${query.get('custom_token') || null}`);
        }

    };

    return (
        <div style={{
            overflow: 'hidden',
            maxHeight: '100vh',
            minHeight: '100vh',
            padding: '10px',
            position: 'relative'
        }}>
            {/* <Button
            startIcon={<ArrowBackIosRounded />}
            style={{
                backgroundColor: '#197D8F',
                color: '#ffffff',
                position: 'absolute',
                top: '10px',
                left: '10px'
            }}
            >
                Volver a Ualabee
            </Button> */}
            <Container id='component'>
                {userCards
                    ? (
                        <>
                            <div>

                                <IconContainer>
                                    {params?.status === 'ok'
                                        ? (
                                            <>
                                                <CheckIcon className={classes.check}/>
                                                <Title>¡Pago exitoso!</Title>
                                            </>
                                        ) : (
                                            <>
                                                <ErrorIcon className={classes.error}/>
                                                <Title style={{
                                                    padding: '0 41px',
                                                    textAlign: 'center',
                                                }}> {getRechargeError(params?.error_id)} </Title>
                                            </>
                                        )

                                    }

                                </IconContainer>
                                <InformationContainer>
                                    {params?.status === 'ok'
                                        ? (
                                            <Info>{company?.approval_text}</Info>
                                        ) : (
                                            <Info>
                                                {params?.error_message}
                                            </Info>
                                        )
                                    }
                                    {parseInt(query.get('rc')) > 0 &&
                                        <ViewTerminals
                                            className={classes.terminal}
                                            onClick={handleViewTerminals}
                                        >
                                            Ver terminales cercanas
                                        </ViewTerminals>
                                    }
                                </InformationContainer>
                                <Items>
                                    {items?.map((item) => {

                                        if (item?.enabled) {

                                            return (
                                                <Item key={item?.name}>
                                                    <ItemName>{item?.name}</ItemName>
                                                    {item?.src || item.checked
                                                        ? (
                                                            <CardType>
                                                                {item.src
                                                                    ? (
                                                                        <>
                                                                            <ItemContent
                                                                                color={item?.color}>{item?.content}</ItemContent>
                                                                            <ItemImage src={item?.src}/>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ItemContent
                                                                                color={item?.color}>{item?.content}</ItemContent>
                                                                            <CheckIcon className={classes.checkSmall}
                                                                                       fontSize='small'/>
                                                                        </>
                                                                    )
                                                                }
                                                            </CardType>
                                                        )
                                                        : (<ItemContent
                                                            color={item?.color}>{item?.content}</ItemContent>)
                                                    }

                                                </Item>
                                            )
                                        }

                                    })}
                                </Items>
                            </div>

                            <div style={{width: '100%'}}>
                                {params?.status === 'ok'
                                    ? (
                                        <>
                                            <DownloadPayment id='download-payment' onClick={printPDF}
                                                             className={classes.pay}>
                                                <PaymentIcon alt='' src={PDF}/>
                                                Descargar comprobante
                                            </DownloadPayment>
                                        {company?.name.toLowerCase() !== 'sube' && (
                                            <SelectAnotherCard
                                                id='select-another-card'
                                                className={classes.select}
                                                onClick={handleSelectAnotherBusCard}
                                            >
                                                Elegir otra {company?.name}
                                            </SelectAnotherCard>
                                        )}
                                        </>
                                    ) : (
                                        <>
                                        {company?.name.toLowerCase() !== 'sube' && (
                                            <DownloadPayment onClick={selectAnother} className={classes.pay}>
                                                Elegir otra tarjeta
                                            </DownloadPayment>
                                        )}
                                        </>
                                    )

                                }
                                <SelectAnotherCard
                                    id='select-another-card'
                                    className={classes.select}
                                    href="https://ualabee.com/"
                                >
                                    Volver a Ualabee
                                </SelectAnotherCard>
                            </div>
                        </>
                    ) : (
                        <LoadingContainer>
                            <CircularProgress color='inherit'/>
                        </LoadingContainer>
                    )
                }
            </Container>
            <Payment items={items}/>
        </div>
    )

}
