import styled from "styled-components/macro";

export const ServiceTypes = styled.div` 
    display: flex;
    align-items: center;
    width: 100%;   
    justify-content: center; 
`;

export const TypesContainer = styled.div`   
    display: flex;
    align-items: flex-start;
    justify-content: ${ props => props?.mode ? 'center' : 'space-between'};
    flex-wrap: ${ props => props?.mode ? 'wrap' : 'no-wrap'}; 
    height: ${ props => props?.mode ? 'auto' : '179px'};
    overflow: ${ props => props?.mode ? 'none' : 'auto'};
    padding: ${ props => props?.mode ? '0' : '0 0 5px 0'};
    overflow-y: hidden;
    
    ::-webkit-scrollbar { 
      width: 6px;
      height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      border-radius: 7px;
      background-color: #E0E8F7; 
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 20px;
    }
`;
