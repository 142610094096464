import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    terminal: {
        height: 32,
        marginBottom: 12,
        width: 'calc(100% - 32px)',
        fontFamily: 'Visby',
        margin: '0 16px',
        borderRadius: 12,
        backgroundColor: 'white',
        textTransform: 'none',
        color: '#117487',
        '&:hover': {
            backgroundColor: 'white',
        },
        fontSize: 12,
    },
    check: {
        width: 43,
        height: 43,
        color: '#5DCD8E',
        marginBottom: 8
    },
    error: {
        width: 43,
        height: 43,
        color: '#F46262',
        marginBottom: 8
    },
    checkSmall: {
        color: '#5DCD8E',
    },
    pay: {
        height: 38,
        backgroundColor: '#197D8F',
        color: 'white',
        textTransform: 'none',
        width: 'calc(100% - 32px)',
        borderRadius: 14,
        '&:hover': {
            backgroundColor: '#197D8F',
        },
        margin: '0 16px',
        display: 'flex',
        marginBottom: 14
    },
    select: {
        height: 38,
        backgroundColor: 'transparent',
        color: '#197D8F',
        textTransform: 'none',
        width: 'calc(100% - 32px)',
        borderRadius: 14,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        margin: '0 16px',
        marginBottom: 14
    }
}));
