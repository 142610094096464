import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    service: {
        boxShadow: '0 2px 4px rgba(34, 40, 49, 0.1)',
        backgroundColor: 'white',
        marginBottom: '15px',
        borderRadius: '8px',
        padding: '16px 8px 15px 16px',
        textTransform: "none",
        lineHeight: 'normal',
        textAlign: 'start',
        fontSize: 'inherit',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    new: {
        position: 'absolute',
        top: -10.5,
        left: 5,
        '&.MuiChip-root': {
            height: 22,
            backgroundColor: '#197D8F',
            color: 'white',
            fontSize: 14
        }
    }
}));

