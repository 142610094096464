import {firebase, ualabeeApiHost} from "../../firebase/firebase-config";
import PropTypes from "prop-types";

export const setUserRechargeCard = async (params) => {

    const token = await firebase.auth().currentUser.getIdToken();

    const reqConfig = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
    };

    return fetch(`${ualabeeApiHost}/payments/userRechargeCards`, reqConfig)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .catch((err) => console.log(err))

}

setUserRechargeCard.propTypes = {
    token: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
}