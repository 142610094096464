import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    headerButton: {
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
        position: 'absolute',
        margin: '12px 16px',
        zIndex: 2
    },
    headerIcon: {
        color: '#A6A9AC',
    },
    buttonSticky: {
        backgroundColor: '#44C554',
        borderRadius: 10,
        '&:hover': {
            backgroundColor: '#44C554',
        },
        width: 'calc(100% - 32px)',
        margin: '17px 0',
        textTransform: 'none',
        fontSize: 16,
        color: 'white',
        '&.MuiButton-label': {
            fontSize: 16,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    slide: {
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: 0
    }
}));

