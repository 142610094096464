import React, {useState} from "react";
import {Dialog, Slide} from "@material-ui/core";
import {useStyles} from "./material";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCard, setOpenEditDialog, setUserCards} from "../../../../actions/recharge";
import {Buttons, Cancel, Container, InputContainer, Rename, Title, Input} from "./styles";
import {editUserRechargeCard} from "../../../../db/movypay/editUserRechargeCard";
import {logEvent} from "../../../../firebase/firebase-config";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const EditCard = () => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const open = useSelector(state => state?.recharge?.components?.edit_card);
    const currentCard = useSelector(state => state?.recharge?.bus_card);
    const cards = useSelector(state => state?.recharge?.user_cards);
    const uid = useSelector(state => state?.auth?.uid);
    const cityConfig = useSelector(state => state?.ui?.cityConfig);

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);

    const handleClose = () => {
        dispatch(setOpenEditDialog(!open));
        setValue('');
        setError(false);
    }

    const handleChange = (e) => {
        setValue(e.target.value)
    };

    const handleRename = async () => {


        const error = (value === currentCard?.name || value?.length === 0) || cards?.some((current) => current?.name === value);

        setError(error);

        if (!error) {

            const currentCards = cards?.map((card) => {
                if (card.id === currentCard?.id) {
                    card.name = value
                }
                return card
            });

            dispatch(setUserCards(currentCards));

            const params = {
                id: parseInt(currentCard?.id),
                name: currentCard?.name,
                card_ref_id: 3735193,
                balance: 300,
                recharge_service_id: 2,
            }

            await editUserRechargeCard(params);

            dispatch(setCurrentCard({
                name: value,
                ...currentCard
            }));

            handleClose();

            logEvent('recharge_card_rename', {
                card_name: currentCard?.name, // String
                card_number: currentCard?.card_ref_id, // String
                card_id: currentCard?.id, // Int
                card_company_name: currentCard?.recharge_services?.name, // String
                user_id: uid, // String
                city_id: cityConfig?.city_id // Int
            });

        }

    };

    return (
        <Dialog
            open={open}
            className={classes.dialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="delete-card"
        >
            <Container>

                <Title>Renombrar tarjeta</Title>
                <InputContainer>
                    <Input
                        id="outlined-number"
                        label="Nombre de tarjeta"
                        type="text"
                        color={error ? '#f44336' : '#197D8F'}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        placeholder={currentCard?.name}
                        value={value}
                        onChange={handleChange}
                        autoFocus={true}
                        required
                        error={error}
                        autoComplete={false}
                        helperText={error && 'Este nombre ya existe'}
                    />
                </InputContainer>

                <Buttons>
                    <Cancel onClick={handleClose} className={classes.option}>Cancelar</Cancel>
                    <Rename onClick={handleRename} className={classes.option}>Confirmar</Rename>
                </Buttons>
            </Container>

        </Dialog>
    )

}
