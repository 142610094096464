import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    result: {
        textTransform: 'none',
        padding: 0,
        marginBottom: 10,
        borderRadius: 8,
        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontFamily: 'Visby',
            lineHeight: 'normal'
        },
    },
}));
