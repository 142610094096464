import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    price: {
        width: 'calc(50% - 12px) !important',
        fontFamily: 'Visby',
        lineHeight: 'none',
        border: '1px solid #E8E8E8',
        margin: 6,
        borderRadius: 10,
        minHeight: 38
    },
    selected: {
        width: 'calc(50% - 12px)',
        fontFamily: 'Visby',
        lineHeight: 'none',
        border: '1px solid #197D8F',
        margin: 6,
        borderRadius: 10,
        color: '#197D8F',
        backgroundColor: '#e8f2f4'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    error: {
        color: '#F46262',
        width: 40,
        height: 40,
    },
    accept: {
        width: 220,
        border: '1px solid #E8E8E8',
        borderRadius: 10,
        backgroundColor: '#ffffff',
        color: '#222831',
        textTransform: 'none'
    }
}));
