import React from 'react';
import {
    Container,
    Description,
    Image,
    ImageContainer,
    InformationContainer,
    Name,
    Price,
    PriceContainer,
    ServiceContainer
} from "./styles";
import {useStyles} from "./materialStyles";
import {useServices} from "../../../../hooks/useServices";
import {Discount, OldPrice} from "../../details/Details/styles";
import {Chip} from "@material-ui/core";
import {useSelector} from "react-redux";
import {helmetJsonLdProp} from "react-schemaorg";
import {Helmet} from "react-helmet";

export const Service = ({name, description, icon, billingPeriod, price, service, services, from, serviceRef}) => {

    const classes = useStyles();

    const {handleClickService} = useServices();

    const servicePrice = service?.discount_percentage_value !== 0 ? service?.price - ((service?.discount_percentage_value / 100) * service?.price) : service?.price

    const mode = useSelector(state => state?.ui?.mode);

    return (
        <ServiceContainer
            ref={service?.service_action_id === 5 ? serviceRef : null}
            mode={mode}
            style={{
                width: mode ? '100%' : '49%',
                margin: mode ? ('0 0 15px 0') : (services.length <= 2 ? '0' : '0 0 18px 0'),
            }}
            className={classes.service}
            onClick={() => handleClickService(service, from)}
        >

            {service?.new_service && <Chip className={classes.new} label={'Nuevo'}/>}
            <Container>
                <InformationContainer>
                    <Name mode={mode}>{name}</Name>
                    <Description>{description}</Description>
                    <PriceContainer>
                        {billingPeriod?.id === 3
                            ? <>
                                {service.discount_percentage_value && service.discount_percentage_value !== 0
                                    ? (
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#198F4C',
                                            marginTop: 5
                                        }}>
                                            {service.discount_percentage_value && service.discount_percentage_value !== 0 &&
                                                <Discount>-{service?.discount_percentage_value?.toString()?.split(".")[0]}%</Discount>}
                                            <Price style={{color: 'black'}}>
                                                ${servicePrice?.toLocaleString(navigator.language, {maximumFractionDigits: 2})}
                                            </Price>
                                            {service.discount_percentage_value && service.discount_percentage_value !== 0 &&
                                                <OldPrice>
                                                    ${service?.price?.toLocaleString(navigator.language, {maximumFractionDigits: 2})}
                                                </OldPrice>
                                            }
                                        </div>
                                    ) : (
                                        <>
                                            {price &&
                                                <Price>
                                                    Desde ${price?.toLocaleString(navigator.language, {maximumFractionDigits: 2})}
                                                </Price>
                                            }
                                        </>

                                    )
                                }

                            </>
                            : (
                                <>
                                    {price &&
                                        <Price>Desde {price?.toLocaleString(navigator.language)} por {billingPeriod?.name?.toLowerCase()}</Price>
                                    }
                                </>
                            )
                        }
                    </PriceContainer>
                </InformationContainer>
                <ImageContainer>
                    <Image
                        src={icon}
                    />
                </ImageContainer>
            </Container>
            <Helmet
                script={[
                    helmetJsonLdProp({
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": name,
                        "image": icon,
                        "slogan": description,
                        "offers": {
                            "@type": "Offer",
                            "priceCurrency": "ARS",
                            "price": servicePrice?.toLocaleString(navigator.language, {maximumFractionDigits: 2})
                        },
                        "description": service?.content,
                    }),
                ]}
            />
        </ServiceContainer>
    )
}

// TODO: set proptypes to Service component
