import {setSearchValue} from "../actions/services";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {setOpenSearch} from "../actions/ui";
import {useEffect, useState} from "react";

export const useSearch = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {pathname} = useLocation();

    const {value} = useSelector(state => state?.services?.search);
    const services = useSelector(state => state?.services?.services?.services);
    const mode = useSelector(state => state?.ui?.mode);

    const [results, setResults] = useState();
    const [showResults, setShowResults] = useState();

    useEffect(() => {
        if (!mode) {
            setShowResults(value?.length > 0 && results?.length > 0);
        }
    }, [value, results])

    useEffect(() => {

        if (value?.length >= 1) {
            const results = services?.filter((service) => {
                return !!(
                    service.name
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .includes(
                            value
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toLowerCase()
                        ) ||
                    service?.type?.name
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .includes(
                            value
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toLowerCase()
                        ) ||
                    service?.description
                        ?.normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .includes(
                            value
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toLowerCase()
                        ) ||
                    service?.group?.name
                        ?.normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .includes(
                            value
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toLowerCase()
                        )
                );
            })
            setResults(results)
        }

    }, [value, services])

    /**
     * handleChangeSearch()
     * @param {Object} e
     */
    const handleChangeSearch = (e) => {
        dispatch(setSearchValue(e.target.value))
    };

    const handleSearch = () => {
        if (mode) {
            if (pathname !== '/search') {
                history.push('/search')
            }
        } else {
            dispatch(setOpenSearch(true))
        }
    };

    return {
        handleChangeSearch,
        handleSearch,
        results,
        showResults
    }
}
