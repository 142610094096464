import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { authReducer } from "../reducers/authReducer";
import { servicesReducer } from "../reducers/servicesReducer";
import {uiReducer} from "../reducers/uiReducer";
import {rechargeReducer} from "../reducers/rechargeReducer";
import {dbReducer} from "../reducers/dbReducer";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;


const reducers = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    services: servicesReducer,
    db: dbReducer,
    recharge: rechargeReducer
})


export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware( thunk )
    )
    );
