import React, {useEffect, useRef} from 'react';
import {useServices} from "../../../../../../hooks/useServices";
import {ServiceGroup} from "../../../../../services/notFeatured/ServiceGroup";
import {ComponentContainer} from "./styles";
import useQuery from "../../../../../../hooks/useQuery";

export const Services = () => {

    const query = useQuery();
    const serviceRef = useRef(null)

    const {
        groupedServices
    } = useServices();

    useEffect(() => {
        if (serviceRef?.current && query.get('custom_token')) {
            serviceRef?.current?.click();
        }
    }, [groupedServices])

    return (
        <ComponentContainer>
            {groupedServices?.map((group) => {
                return (
                    <ServiceGroup
                        key={group?.groupId}
                        group={group}
                        serviceRef={serviceRef}
                        id={group?.name?.split(' ').join('-').replace('&', 'y').toLowerCase()}
                    />
                )
            })}
        </ComponentContainer>
    )
}
