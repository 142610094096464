import React from "react";
import {useSelector} from "react-redux";
import {CardContainer, Container, Data, Name, Number} from "./styles";
import {pickTextColorBasedOnBgColor} from "../../../utils";

export const Card = () => {

    const company = useSelector(state => state?.recharge?.company);
    const busCard = useSelector(state => state?.recharge?.bus_card);

    const currentNumber = busCard?.number?.length > 0 ? `${busCard?.number?.substring(0, 4) + ' ' + busCard?.number?.substring(4, 8) + ' ' + busCard?.number?.substring(8, 12) + ' ' + busCard?.number.substring(12, 16)}` : '0000 0000 0000 0000'
    const currentName = busCard?.name?.length > 0 ? busCard?.name : 'Nombre de la tarjeta'

    const textColor = pickTextColorBasedOnBgColor(company?.color, '#fff', '#3D3E3F')

    return (
        <CardContainer>
                <Container src={company?.background_url}>
                    <Data>
                        <Number color={textColor}>{currentNumber}</Number>
                        <Name color={textColor}>{currentName}</Name>
                    </Data>
                </Container>
        </CardContainer>

    )

}
