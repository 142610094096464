import {firebase, ualabeeApiHost} from '../firebase/firebase-config';
import {getLocalVariantId} from "./getLocalVariantId";

export const getServiceTypes = async () => {

    const token = await firebase.auth().currentUser.getIdToken();

    const reqConfig = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            'variant-id': getLocalVariantId()
        }
    };

    return fetch(`${ualabeeApiHost}/marketplace/getServiceTypes`, reqConfig)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((response) => response?.result)
        .catch((err) => console.log(err));

}
