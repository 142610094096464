 import styled from "styled-components/macro";
 import {Button, InputBase, SwipeableDrawer} from "@material-ui/core";

 export const CountriesDrawer = styled(SwipeableDrawer)``;

 export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px;
    height: 80vh;
    overflow: hidden hidden;
    
    ::-webkit-scrollbar { 
      width: 6px;
      height: 6px;

    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      border-radius: 7px;
      background-color: #E0E8F7;  
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0;  
      border-radius: 7px;
      height: 164px;
    }
 `;

 export const Navbar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column; 
    position: relative;
 `;

 export const Country = styled(Button)``;

 export const Title = styled.h1`  
    font-family: Visby;
    color: rgb(0, 0, 0);
    font-size: 18px;
    margin: 12px 0px 16px;
    padding: 0px;
    text-align: center;
 `;

 export const Flag = styled.img` 
    width: 38px;
    margin-right: 23px;
 `;

 export const Name = styled.h2`  
    font-family: Visby;
    color: rgb(0, 0, 0);
    font-size: 18px;
    margin: 0px;
    padding: 0px;
 `;

 export const CountriesList = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin: 0px 0px 20px;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    overflow: auto;
    max-height: calc(100% - 100px);
 `;

 export const Searcher = styled(InputBase)`  
`;

 export const SearchContainer = styled.div` 
    width: 100%;
    position: relative; 
`;