import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCityConfig, setOpenCountries} from "../../../../actions/ui";
import {Dialog, Menu, MenuItem} from "@material-ui/core";
import {CloseButton, CloseIcon, Container, CountriesList, Country, Flag, Navbar, Name, Title} from "./styles";
import {useStyles} from "./materialStyles";
import {getAllCities} from "../../../../db/getAllCities";
import {logEvent} from "../../../../firebase/firebase-config";
import {getDistanceFromLatLonInKm} from "../../../../utils";
import {getConfig} from "../../../../db/getConfig";
import {setLocalCityConfig} from "../../../../db/setLocalCityConfig";
import {getServices} from "../../../../db/getServices";
import {getServiceTypes} from "../../../../db/getServiceTypes";
import {setAllServices, setLoading} from "../../../../actions/services";
import {ArrowDropDown} from "@material-ui/icons";

export const Countries = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const cityConfig = useSelector(state => state?.ui?.cityConfig);
    const open = useSelector(state => state?.ui?.components?.countries);
    const countries = useSelector(state => state?.db?.countries);
    const userPosition = useSelector(state => state?.ui?.userPosition);
    const uid = useSelector(state => state?.auth?.uid);

    const [city, setCity] = useState();
    const [currentCities, setCurrentCities] = useState();
    const [currentCountry, setCurrentCountry] = useState();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        if (city || cityConfig) {
            dispatch(setOpenCountries(false));
        } else {
            alert('Debes seleccionar una ciudad')
        }
    }

    const handleClickCountry = async (e, country) => {

        setAnchorEl(e?.currentTarget);

        const eventParams = {
            lat: userPosition ? userPosition.lat : null,
            lng: userPosition ? userPosition.lng : null,
            last_country_id: cityConfig?.country?.country_id || -1,
            last_country_name: cityConfig?.country?.name_es || null,
            country_id: country.country_id,
            country_name: country.name_es,
            user_id: uid,
            os: 'web',
        };
        logEvent("marketplace_change_country", eventParams);


        const cities = await getAllCities(country?.country_id)

        setCurrentCities(cities)
        setCurrentCountry(country)
    }

    const handleClickCity = async (city) => {

        setCity(city)
        setAnchorEl(null);
        setCurrentCountry(undefined);
        dispatch(setOpenCountries(false));

        const eventParams = {
            lat: userPosition ? userPosition.lat : null,
            lng: userPosition ? userPosition.lng : null,
            last_city_id: cityConfig ? cityConfig?.city_id : -1,
            last_city_name: cityConfig ? cityConfig?.name : null,
            city_id: city?.city_id,
            city_name: city?.name,
            city_distance: userPosition
                ? getDistanceFromLatLonInKm(
                    userPosition?.lat,
                    userPosition?.lng,
                    city?.map_center_lat,
                    city?.map_center_lng
                )
                : null,
            user_id: uid,
            os: 'web',
        };

        logEvent("marketplace_change_city", eventParams);

        const currentCityConfig = await getConfig(city?.city_id);

        setLocalCityConfig(currentCityConfig);

        const services = await getServices(currentCityConfig?.city_id);
        const serviceTypes = await getServiceTypes();

        const allServices = {
            news: services?.new_services,
            featured: services?.featured_services,
            services: services?.services,
            types: serviceTypes
        }

        dispatch(setAllServices(allServices));
        dispatch(setCityConfig(currentCityConfig));
        dispatch(setLoading(false));

    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick={false}
            onBackdropClick={handleClose}
            classes={{paper: classes.paper}}
        >
            <Container>
                <Navbar>
                    <Title>Seleccioná tu ciudad</Title>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon/>
                    </CloseButton>
                </Navbar>
                <CountriesList>
                    {countries?.map((country) => {
                        return (
                            <div key={country.id} style={{position: 'relative', width: '50%'}}>
                                <Country
                                    key={country?.country_id}
                                    classes={{root: classes.country, text: classes.text}}
                                    onClick={(e) => handleClickCountry(e, country)}
                                >
                                    <Flag src={country?.flag_uri}/>
                                    <Name>{country?.name_es}</Name>
                                    <ArrowDropDown className={classes.dropIcon}/>
                                </Country>

                                {country === currentCountry &&
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onBackdropClick={() => setCurrentCountry({})}
                                        className={classes.menu}
                                    >
                                        {currentCities?.map((city) => {
                                            return (
                                                <MenuItem
                                                    key={city?.city_id}
                                                    onClick={() => handleClickCity(city)}
                                                >
                                                    {city?.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Menu>
                                }


                            </div>

                        )
                    })}
                </CountriesList>
            </Container>
        </Dialog>
    )
}