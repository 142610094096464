import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    modal: {
        width: '100%',
        borderRadius: '14px',
        '& .MuiDialogTitle-root': {
            display: 'flex',
            color: 'inherit',

        },
        '& .MuiPaper-rounded': {
            borderRadius: '14px',
            maxWidth: '80vw',
        },
        '& .MuiTypography-colorTextSecondary': {
            color: 'inherit',
            fontFamily: 'inherit',
        },
        '& .MuiDialogContent-root': {
            padding: '0 50px 0 50px'
        }
    },
    buttonContent: {
        width: '100%',
        color: 'white',
        textTransform: 'none'
    }
}));

