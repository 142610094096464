import React from "react";

import {CardContainer, CardTitle} from "./styles";

const ImageCard = ({
                       item,
                       title,
                       backgroundImg,
                       onClickFunction,
                   }) => {
    return (
        <CardContainer
            backgroundImg={backgroundImg}
            onClick={() => onClickFunction(item)}
        >
            <CardTitle>{title}</CardTitle>
        </CardContainer>
    );
};

export default ImageCard;
