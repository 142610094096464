import React, {useEffect, useRef} from 'react';
import {BackIcon, Results, SearchButton, SearcherContainer, SearcherIcon, SearcherInput, SearchResults} from "./styles";
import {useSearch} from "../../../hooks/useSearch";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useStyles} from "../Search/materialStyles";
import {Result} from "../../../uiComponents/search/results/Result";
import {setOpenSearch} from "../../../actions/ui";
import {setSearchValue} from "../../../actions/services";
import {HelperMessage} from "../../../uiComponents/search/results/HelpertMessage";

export const Searcher = ({autoFocus = false}) => {

    const history = useHistory();
    const classes = useStyles();

    const dispatch = useDispatch();

    const {
        handleChangeSearch,
        handleSearch,
        results,
        showResults
    } = useSearch();

    const mode = useSelector(state => state?.ui?.mode);
    const searcher = useSelector(state => state?.ui?.components?.searcher)
    const {value} = useSelector(state => state?.services?.search);

    const handleBack = () => {
        history.goBack()
    }

    const styleSearcher = {
        width: mode ? '100%' : '448px',
        margin: mode ? '24px 0 32px 0' : '0',
        height: mode ? '40px' : '40px',
        border: mode ? 'none' : '1px solid #E0E8F7',
        backgroundColor: mode ? '#F6F8F9' : 'white',
        borderRadius: 10,
        borderBottomLeftRadius: searcher?.enabled ? '0' : '10px',
        borderBottomRightRadius: searcher?.enabled ? '0' : '10px',
        borderBottom: searcher?.enabled ? 'none' : '1px solid #E0E8F7',
    }

    function useOutside(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    dispatch(setOpenSearch(false))
                    dispatch(setSearchValue(''))
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener('scroll', handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    function ResultsContainer(props) {
        const wrapperRef = useRef(null);
        useOutside(wrapperRef);
        return <Results ref={wrapperRef}>{props.children}</Results>;
    }


    return (
        <div style={{}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <SearcherContainer
                    style={styleSearcher}
                    onClick={handleSearch}
                >
                    {autoFocus
                        ? <BackIcon onClick={handleBack}/>
                        : <SearcherIcon/>
                    }

                    <SearcherInput
                        style={{fontFamily: 'Visby'}}
                        autoFocus={autoFocus}
                        placeholder={'Buscar productos o servicios'}
                        onChange={handleChangeSearch}
                        value={value}
                    />

                </SearcherContainer>
                {!mode &&
                <SearchButton className={classes.searchButton}>
                    Buscar
                </SearchButton>
                }
            </div>
            {searcher?.enabled &&
            <SearchResults>
                {showResults
                    ? (
                        <ResultsContainer>
                            {results?.map(result => <Result key={result?.id} result={result} from={'autocomplete'}/>)}
                        </ResultsContainer>
                    ) : (
                        <ResultsContainer>
                            <HelperMessage/>
                        </ResultsContainer>
                    )
                }
            </SearchResults>

            }

        </div>
    )
}
