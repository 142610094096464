import React from 'react';
import {ComponentContainer, Container} from "./styles";
import {FeaturedServices} from "../../../../../services/featured/FeaturedServices";

export const Featured = () => {
    return (
        <ComponentContainer>
            <Container>
                <FeaturedServices/>
            </Container>
        </ComponentContainer>
    )
}
