import React from 'react';
import {ComponentContainer, MessageIcon, MessageText} from "./sttyles";

export const HelperMessage = () => {
    return (
        <ComponentContainer>
            <MessageIcon fontSize={'large'}/>
            <MessageText>
                Comenzá buscando algún producto o servicio. <br/>
            </MessageText>
        </ComponentContainer>
    )
}
