import React from 'react';
import PropTypes from "prop-types";
import {Container, Name, ServiceGroupContainer, ServicesContainer, ShowMore} from "./styles";
import {Service} from "../Service";
import {useStyles} from "./materialStyles";
import {useServices} from "../../../../hooks/useServices";
import {useSelector} from "react-redux";
import {helmetJsonLdProp} from "react-schemaorg";
import {Helmet} from "react-helmet";

export const ServiceGroup = ({group, id, serviceRef}) => {

    const classes = useStyles();

    const {name, color, icon, services, groupId} = group;
    const mode = useSelector(state => state?.ui?.mode);

    const {
        handleShowMore,
    } = useServices();

    return (
        <ServiceGroupContainer
            color={color}
            id={id}
        >
            <div
                style={
                    mode
                        ? {padding: 20}
                        : {display: 'flex', alignItems: 'center', flexDirection: 'column'}
                }
            >
                <Container mode={mode}>
                    <Name color={color}>
                        {name}
                    </Name>
                    {mode &&
                    <ShowMore
                        className={classes.button}
                        style={{color: color}}
                        onClick={() => handleShowMore(group)}
                    >
                        Ver todos
                    </ShowMore>
                    }
                </Container>
                <ServicesContainer mode={mode}>
                    {services?.map((service, i) => {
                        return (
                            <Service
                                key={i}
                                serviceRef={serviceRef}
                                name={service?.name}
                                description={service?.description}
                                icon={service?.icon_url}
                                billingPeriod={service?.billing_period}
                                groupName={service?.group?.name}
                                price={service?.price}
                                service={service}
                                services={services}
                                from={'listing'}
                            />
                        )
                    })}
                </ServicesContainer>
            </div>
            <Helmet
                script={[
                    helmetJsonLdProp({
                        "@context": "https://schema.org/",
                        "@type": "ProductGroup",
                        "productGroupID": groupId,
                        "hasVariant": name.replace('&', 'y'),
                        "variesBy": [color, name.replace('&', 'y'), icon, services],
                        "offers": "Offer"
                    }),
                ]}
            />
        </ServiceGroupContainer>
    )
}

ServiceGroup.propTypes = {
    group: PropTypes.object.isRequired,
}

