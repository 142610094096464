import styled from "styled-components/macro";
import {IconButton} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";

export const Container = styled.div`  
    height: 44px;   
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
`;

export const BackButton = styled(IconButton)`   
`;

export const BackIcon = styled(ArrowBack)`    
`;

export const Title = styled.h1`  
    margin: 0;
    font-family: Visby;
    font-size: 16px;
    color: #3D3E3F;  
`;
