import React from 'react';
import {SkeletonContainer} from "./styles";
import {Skeleton} from "@material-ui/lab";
import {CircularProgress} from "@material-ui/core";

export const SkeletonMobile = () => {

    const items = [{id: 12}, {id: 96}, {id: 65}, {id: 254}, {id: 5415}];

    return (
        <SkeletonContainer>
            <div style={{
                position: 'absolute',
                zIndex: 1,
                top: 0 ,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div
                    style={{
                        width: '80%',
                        backgroundColor: 'white',
                        boxShadow: '0px 0px 24px #22283126',
                        borderRadius: 8,
                        padding: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <div>
                        <CircularProgress style={{marginRight: 16, color: '#197d8f', width: 30, height: 30}}/>
                    </div>
                        Estamos buscando las mejores ofertas para vos...

                </div>
            </div>
            <div
                style={{
                    padding: '0 16px'
                }}
            >

                <Skeleton
                    style={{
                        margin: '24px 0 32px 0',
                        borderRadius: 10
                    }}
                    variant='rect'
                    width={'100%'}
                    height={40}
                />

                <div
                    style={{
                        maxWidth: '100%',
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        flexWrap: 'wrap'
                    }}
                >

                    {items?.map((item) => {
                        return (
                            <div
                                key={item?.id}
                                style={{
                                    width: '33.33%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontFamily: 'Visby',
                                    flexDirection: 'column',
                                    textTransform: 'none',
                                    justifyContent: 'end',
                                }}
                            >
                                <Skeleton
                                    style={{borderRadius: 14}}
                                    width={64}
                                    height={64}
                                    variant='rect'
                                />
                                <Skeleton
                                    width={64}
                                    height={14}
                                    variant='rect'
                                    style={{
                                        margin: '4px 0 10px 0',
                                        borderRadius: 14
                                    }}
                                />
                            </div>
                        )
                    })}

                </div>

                <Skeleton
                    width={156}
                    height={21}
                    variant='rect'
                    style={{
                        borderRadius: 8,
                        marginTop: 22,
                        marginBottom: 12
                    }}
                />

                <Skeleton
                    width={'100%'}
                    height={112}
                    variant='rect'
                    style={{
                        borderRadius: 8,
                    }}
                />

                <Skeleton
                    width={195}
                    height={21}
                    variant='rect'
                    style={{
                        borderRadius: 8,
                        marginTop: 22,
                        marginBottom: 12
                    }}
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        paddingBottom: '10px',
                    }}
                >

                    <Skeleton
                        variant='rect'
                        style={{
                            width: '30%',
                            padding: 0,
                            minWidth: '30%',
                            marginRight: '15px',
                            height: 122,
                            borderRadius: 8,
                        }}
                    />

                    <Skeleton
                        variant='rect'
                        style={{
                            width: '30%',
                            padding: 0,
                            minWidth: '30%',
                            marginRight: '15px',
                            height: 122,
                            borderRadius: 8,
                        }}
                    />

                    <Skeleton
                        variant='rect'
                        style={{
                            width: '30%',
                            padding: 0,
                            minWidth: '30%',
                            marginRight: '15px',
                            height: 122,
                            borderRadius: 8,
                        }}
                    />
                </div>

            </div>

            <div>
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '14px 16px'
                }}
                >
                    <Skeleton
                        variant='rect'
                        width={35}
                        height={35}
                        style={{
                            borderRadius: 8,
                        }}
                    />

                    <Skeleton
                        variant='rect'
                        width={122}
                        height={21}
                        style={{
                            borderRadius: 8,
                        }}
                    />

                    <Skeleton
                        variant='rect'
                        width={70}
                        height={28}
                        style={{
                            borderRadius: 8,
                        }}
                    />
                </div>

                <div
                style={{padding:'0 16px 18px 16px'}}
                >
                    <Skeleton
                        variant='rect'
                        style={{
                            width: '100%',
                            height: 135,
                            borderRadius: 8,
                        }}
                    />
                </div>

            </div>

        </SkeletonContainer>
    )
}
