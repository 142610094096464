import styled from "styled-components/macro";
import {Button} from "@material-ui/core";

export const ServiceGroupContainer = styled.div` 
    background-color: ${props => props?.color && props?.color}24; 
    margin-bottom: 16px;
    
    &:first-child { 
        margin-top: 32px; 
    } 
    
    &:last-child { 
        margin-bottom: 0; 
    }
`

export const Container = styled.div` 
    width: ${props => props?.mode ? '100%' : '60%'}; 
    align-items: ${props => props?.mode ? 'center' : 'center'};
    display: flex;
    justify-content: ${props => props?.mode ? 'space-between' : 'flex-start'};
    padding: ${props => props?.mode ? '0' : '14px 16px'};  
    margin-bottom: ${props => props?.mode ? '15px' : 'inherit'};
`

export const Name = styled.h2` 
    color: ${props => props?.color && props?.color}; 
    font-size: 18px;
    margin: 0; 
`

export const ShowMore = styled(Button)` 
    font-size: 16px; 
    font-weight: 400;
    color: ${props => props?.color && props?.color};  
    text-transform: none; 
    font-family: Visby; 
    padding: 0 8px;
`

export const ServicesContainer = styled.div`   
    padding: ${props => props?.mode ? '0' : '0 0 18px 0'};  
    width: ${props => props?.mode ? '100%' : '60%'};  
    max-width: ${props => props?.mode ? '100%' : '60%'};  
    
    display: ${props => props?.mode ? 'inherit' : 'flex'};  
    align-items: ${props => props?.mode ? 'inherit' : 'center'};  
    justify-content: ${props => props?.mode ? 'inherit' : 'space-between'};  
    flex-wrap: ${props => props?.mode ? 'inherit' : 'wrap'};   
`

