import styled from "styled-components/macro";

export const Container = styled.div`
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
    width: 100%;
    aspect-ratio: 1.6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-radius: 14px;
    max-width: 390px;
`;

export const CardContainer = styled.div` 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 0 16px;
    box-sizing: border-box;
`;

export const Image = styled.img`
    max-width: ${props => props.extend ? 'auto' : '58px'};
`;

export const Name = styled.p`
    margin: 4px 0 0 0;   
    color: ${props => props.color ? props.color : '#3D3E3F'};
`;

export const Number = styled.p`
    margin: 0;   
    color: ${props => props.color ? props.color : '#3D3E3F'};
`;

export const Data = styled.div` 
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
`;
