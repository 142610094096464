import {firebase, ualabeeApiHost} from "../../firebase/firebase-config";

export const getCompanyAmounts = async (rechargeCompany) => {

    const token = await firebase.auth().currentUser.getIdToken();

    const reqConfig = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
        },
        body: `{"payment_ref_id": "${rechargeCompany}"}`,
    };

    return fetch(`${ualabeeApiHost}/payments/getAmountsByPaymentRefId`, reqConfig)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((response) => response?.result)
        .catch((err) => console.log(err))

    /*
        const reqBody = {
            "url": `${rechageAmountsApi.urlMovypay}/${rechargeCompany}`,
            "body": "",
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": rechageAmountsApi.credential,
            }
        };

        const reqConfig = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody),
        };

        return fetch(rechageAmountsApi.url, reqConfig)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((response) => response)
            .catch((err) => console.log(err))
     */

}
