import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    secondary: {
        color: '#197D8F',
        backgroundColor: 'transparent',
        textTransform: 'none',
        width: 'calc(100% - 32px)',
        borderRadius: 14,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        margin: '0 16px',
        display: 'flex',
        position: 'absolute',
        fontFamily: 'Visby'
    },
}));
