import React from "react";
import {Confirm} from "./styles";
import {useStyles} from "./material";
import PropTypes from "prop-types";

export const Primary = ({disabled, onClickFunction, label, bottom}) => {

    const classes = useStyles();

    return (
        <Confirm
            style={{bottom: bottom}}
            className={`${disabled ? classes.disabled : classes.continue}`}
            onClick={onClickFunction}
            type='submit'
            disabled={disabled}
        >
            {label}
        </Confirm>
    )

}

Primary.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClickFunction: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    bottom: PropTypes.number.isRequired,
}
