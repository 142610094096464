import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    card: {
        width: '100%',
        position: 'relative',
        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        lineHeight: 'normal',
        textTransform: 'none',
        fontFamily: 'Visby',
    },
    delete: {
    }
}));
