import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    paper: {
        backgroundColor: '#ffffff',
        outline: 'none !important',
        padding: '24',
        boxShadow: '0px 0px 24px #22283126',
        borderRadius: 8
    },
    country: {
        width: '100%',
        textTransform: 'none',
        fontFamily: 'Visby',
        fontSize: 18,
        color: 'rgb(0, 0, 0)',
        padding: '6px 8px',
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    search: {
        position: 'absolute',
        top: 11.5,
        right: 10,
        color: 'rgb(166, 169, 172)'
    },
    searcher: {
        width: '100% !important',
        border: '1px solid #707070',
        borderRadius: '10px',
        padding: '16px 40px 16px 20px;',
        boxSizing: 'border-box',
        fontFamily: 'Visby',
        color: '#000000',
        fontSize: '14px',
        marginBottom: '8px',
        height: '50px',
        position: ' relative',
    },
    dropIcon: {
        color: '#757575'
    }
}));
