import styled from "styled-components/macro";
import {Button} from "@material-ui/core";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw; 
`;

export const FormContainer = styled.div` 
    margin-top: 40px;  
    margin-bottom: 20px;  
    padding: 0 16px; 
`;

export const DataContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`

export const Confirm = styled(Button)` 
`;
