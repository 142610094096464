import React from 'react';
import {ComponentContainer, Container} from "./styles";
import {NewsContainer, Title} from "../../../../../services/news/News/style";
import {New} from "../../../../../services/news/New";
import {useSelector} from "react-redux";

export const News = () => {

    const news = useSelector(state => state?.services?.services?.news);
    const mode = useSelector(state => state?.ui?.mode);

    return (
        <ComponentContainer>
            <Container>
                <Title mode={mode}>Nuevos en la Tienda</Title>
                <NewsContainer>
                    {news?.map((service) => {
                        return (
                            <New key={service?.id} icon={service?.icon_url} name={service?.name} service={service} from={'listing'}/>
                        )
                    })}
                </NewsContainer>
            </Container>
        </ComponentContainer>
    )
}
