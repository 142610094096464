import styled from "styled-components/macro";

export const ComponentContainer = styled.div`
    box-shadow: 0 1px 1px rgba(232, 237, 238, 0.5); 
    margin: 0 0 35px 0;  
`

export const Container = styled.div`      
    display: flex;
    align-items: center;
    justify-content: space-between;    
    padding: 24px 70px;
`

export const Logo = styled.img` 
    width: 120px;
    height: 50px; 
    pointer-event: none;  
`
