import React from 'react';
import {useSelector} from "react-redux";
import {New} from "../New";
import {Title, NewsContainer, Container} from "./style";

export const News = () => {

    const news = useSelector(state => state?.services?.services?.news)

    return (
        <Container>
            <Title>Nuevos en la Tienda</Title>
            <NewsContainer>
                {news?.map((service) => {
                    return (
                        <New key={service?.id} icon={service?.icon_url} name={service?.name} service={service} from={'listing'}/>
                    )
                })}
            </NewsContainer>
        </Container>
    )
}
