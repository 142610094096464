import {firebase, ualabeeApiHost} from "../firebase/firebase-config";

export const getCityByLatLng = async (params) => {

    const reqBody = {
        lat: params?.lat,
        lng: params?.lng,
    };

    const token = await firebase.auth().currentUser.getIdToken();

    const reqConfig = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqBody),
    };


    return fetch(`${ualabeeApiHost}/cities/getByLatLng`, reqConfig)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((response) => response?.result)
        .catch((err) => console.log(err));


}
