import {types} from "../types/types";

export const setCities = (cities) => ({
    type: types.cities_list,
    payload: cities
})

export const setCountries = (countries) => ({
    type: types.countries_list,
    payload: countries
})
