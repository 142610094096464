import styled from "styled-components/macro";

export const Container = styled.div` 
    margin-bottom: ${ props => props?.mode ? '32px' : '40px'};
`;

export const Title = styled.h2` 
    margin: ${ props => props?.mode ? '22px 0 12px 0' : '0 0 12px 0'}; 
    font-size: ${ props => props?.mode ? '18px' : '16px'};
    color: #222831; 
`;

export const Image = styled.div`
    width: 100%;
    height: 112px;
    border-radius: 8px;  
    background-image: url(${props => props?.img && props?.img});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`;

export const Shadow = styled.div` 
    width: 100%;
    height: 112px;
    background-color: #2228318f; 
    position: absolute;
    top: 0;
    border-radius: 8px;  
`;

export const ContentContainer = styled.div`  
    width: 80%;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.p` 
    margin: 0;
    color: white;
    padding-left: 17px;
`;

export const Logo = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 40px;
    background-image: url(${props => props?.src});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;  
    border-radius: 8px;  
    background-color: white; 
`;
