import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    continue: {
        height: 38,
        backgroundColor: '#197D8F',
        color: 'white',
        textTransform: 'none',
        width: 'calc(100% - 32px)',
        borderRadius: 14,
        '&:hover': {
            backgroundColor: '#197D8F',
        },
        margin: '0 16px',
        display: 'flex',
        position: 'absolute'
    },
    disabled: {
        height: 38,
        backgroundColor: '#F2F2F2',
        '&:hover': {
            backgroundColor: '#F2F2F2',
        },
        color: '#BCBEC0',
        textTransform: 'none',
        width: 'calc(100% - 32px)',
        borderRadius: 14,
        margin: '0 16px',
        display: 'flex',
        position: 'absolute'
    },
}));
