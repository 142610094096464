import styled from "styled-components/macro";
import {LocationOn, ScheduleRounded} from "@material-ui/icons";

export const TerminalImage = styled.div` 
    width: 100%;
    height: 80px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    padding: 10px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end; 
    background-image: url(${props => props?.src});
`;

export const TerminalInfo = styled.div`
    font-family: Visby;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    padding: 14px;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const Description = styled.p`  
    margin: 0 0 8px 0;
    font-family: Visby;
    font-style: normal;
    font-weight: normal;
    font-size: 12px; 
    color: #8C8C8C; 
`;

export const Location = styled.h1` 
    margin: 0 0 17px 0;
    font-family: Visby;
    font-style: normal;
    font-weight: 600;
    font-size: 18px; 
    color: #222831;
`;

export const DirectionContainer = styled.div` 
    display: flex;
    align-items: center; 
    margin: 0 0 9px 0;
`;

export const Direction = styled.p`  
    margin: 0; 
    font-family: Visby;
    font-style: normal;
    font-weight: 500;
    font-size: 14px; 
    color: #525252;
`;


export const DirectionIcon = styled(LocationOn)`  
    width: 18px;
    height: 18px; 
    color: #A6A9AC; 
    margin-right: 8px;
`;

export const ScheduleIcon = styled(ScheduleRounded)`  
    width: 18px;
    height: 18px; 
    color: #A6A9AC; 
    margin-right: 8px;
`;