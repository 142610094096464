import styled from "styled-components/macro";
import {Button, IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";

export const Container = styled.div`  
    background-color: #ffffff;
    padding: 12px 12px 24px 24px;
`;

export const Navbar = styled.div`  
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.h1`  
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    font-family: Visby;
`;

export const CloseButton = styled(IconButton)`  
`;

export const CloseIcon = styled(Close)`  
`;

export const CountriesList = styled.div` 
    width: 450px;
    height: 210px; 
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    
    overflow: auto;
    overflow-x: hidden;
    
    ::-webkit-scrollbar { 
      width: 6px;
      height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      border-radius: 7px;
      background-color: #E0E8F7; 
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 164px;
    } 
`;

export const Country = styled(Button)`   
`;

export const Flag = styled.img`  
    width: 38px;
    height: 38px;
    margin-right: 23px;
`;

export const Name = styled.h2`   
    margin: 0px;
    font-size: 18px;
    font-weight: 500;
    font-family: Visby;
`;