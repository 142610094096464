import React, {useEffect, useState} from "react";
import {
    CountriesDrawer,
    CountriesList,
    Country,
    Flag,
    Name,
    Navbar,
    Searcher,
    Title
} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useStyles} from "./materialStyles";
import {SwipeableIcon} from "../../styles";
import {Search} from "@material-ui/icons";
import {SearcherContainer} from "../../components/search/Searcher/styles";
import {setOpenCities, setOpenCountries} from "../../actions/ui";
import {Snackbar} from "@material-ui/core";
import {getAllCities} from "../../db/getAllCities";
import {setCities} from "../../actions/db";
import {logEvent} from "../../firebase/firebase-config";
import {Countries as DesktopCountries} from '../../components/desktop/marketplace/Countries';

export const Countries = () => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const open = useSelector(state => state?.ui?.components?.countries);
    const countries = useSelector(state => state?.db?.countries);
    const userPosition = useSelector(state => state?.ui?.userPosition);
    const cityConfig = useSelector(state => state?.ui?.cityConfig);
    const uid = useSelector(state => state?.auth?.uid);
    const mode = useSelector(state => state?.ui?.mode);

    const [alert, setAlert] = useState();

    const [value, setValue] = useState();
    const [countriesSearch, setCountriesSearch] = useState();

    useEffect(() => {
        if (open && !countriesSearch) {
            setCountriesSearch(countries)
        }
    }, [open])

    const handleOpen = () => {
    }

    const handleClose = () => {
        if (cityConfig) {
            dispatch(setOpenCountries(false));
        } else {
            dispatch(setOpenCountries(false));
            setAlert(true);
            dispatch(setOpenCountries(true));
        }
    };

    const handleClickCountry = async (country) => {

        const cities = await getAllCities(country?.country_id);

        const eventParams = {
            lat: userPosition ? userPosition.lat : null,
            lng: userPosition ? userPosition.lng : null,
            last_country_id: cityConfig?.country?.country_id || -1,
            last_country_name: cityConfig?.country?.name_es || null,
            country_id: country?.country_id,
            country_name: country?.name_es,
            user_id: uid,
        };

        logEvent("marketplace_change_country", eventParams);

        dispatch(setCities(cities));
        dispatch(setOpenCountries(false));
        dispatch(setOpenCities(true));

    }

    const handleChange = (e) => {

        setValue(e.target.value);

        const results = countries?.filter((country) =>
            country.name_es
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                    e.target.value
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                )
        );

        setCountriesSearch(results);

    };

    return (
        <>
            {mode
                ? (
                    <CountriesDrawer
                        disableDiscovery={true}
                        disableSwipeToOpen={true}
                        classes={{
                            paper: classes.paper,
                            modal: classes.modal,
                        }}
                        open={open}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        anchor="bottom"
                    >
                        <Navbar>
                            <SwipeableIcon/>
                            <Title>Elige tu país</Title>
                            <SearcherContainer>
                                <Searcher
                                    placeholder={'¿Qué país buscas?'}
                                    className={classes.searcher}
                                    value={value}
                                    onChange={handleChange}
                                />
                                <Search classes={{root: classes.search}}/>
                            </SearcherContainer>
                        </Navbar>
                        <CountriesList>
                            {countriesSearch?.map((country) => {
                                return (
                                    <Country
                                        key={country.country_id}
                                        classes={{root: classes.country, text: classes.countryText}}
                                        onClick={() => handleClickCountry(country)}
                                    >
                                        <Flag src={country?.flag_uri}/>
                                        <Name>
                                            {country?.name_es}
                                        </Name>
                                    </Country>
                                )
                            })}
                        </CountriesList>
                        <Snackbar
                            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                            open={alert}
                            onClose={() => setAlert(false)}
                            message="Debes seleccionar un país para continuar."
                        />
                    </CountriesDrawer>
                ) : (
                    <DesktopCountries/>
                )
            }
        </>
    )
}
