import styled from "styled-components/macro";
import {CheckCircleRounded} from "@material-ui/icons";

export const Container = styled.div`  
    padding: 5px;  
`;

export const Header = styled.div`   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
`;

export const Title = styled.h1`  
    margin: 4px 0 0 0;  
    font-size: 8px;
    color: #8C8C8C;
    font-weight: 400; 
`;

export const SubTitle = styled.h2`
    margin: 0 0 15px 0;
    font-size: 10px; 
    color: #222831;
    font-weight: 400; 
`;

export const Logo = styled.img`  
    width: 75px;
    margin-top: 10px;
    font-weight: 400;
`;

export const Information = styled.div`
    padding: 24px 37px 0 37px;   
`;

export const Items = styled.div`  
`;

export const Item = styled.div` 
    display: flex; 
    border-bottom: 1px solid #C4C4C4;
    align-items: center;
    justify-content: space-between;
    height: 30px;
`;

export const Text = styled.p`   
    font-size: 8px;
    margin: 10px 0;
    color: #525252;
`;

export const ContentContainer = styled.div`  
`;

export const BusInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const Content = styled.p`  
    margin: 0;
    font-size: 8px;
    color: ${props => props?.color};
    margin-right: 8px;
`;

export const Network = styled.div`
    width: 25px;
    height: 14px;
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
`;

export const CheckIcon = styled(CheckCircleRounded)`   
`;

export const Footer = styled.div` 
    display: flex;
    align-items: center;
    margin-top: 24px;
`;

export const Info = styled.p` 
    margin: 0;
    font-size: 8px;
    color: #8C8C8C;
`;
