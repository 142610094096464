import React from 'react'
import "swiper/swiper.min.css";
import {useSelector} from "react-redux";
import {Marketplace as MarketplaceMobile} from "./components/mobile/marketplace/Marketplace";
import {Marketplace as MarketplaceDesktop} from "./components/desktop/marketplace/Marketplace";

export const App = () => {

    const mode = useSelector(state => state?.ui?.mode);

    return (
        <>
            {mode ? <MarketplaceMobile/> : <MarketplaceDesktop/>}
        </>
    )
}
