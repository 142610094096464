import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useSelector} from "react-redux";
import {Container, Content, ContentContainer, Image, Logo, Shadow, Title} from "./style";
import "swiper/swiper.min.css";
import {useServices} from "../../../../hooks/useServices";
import SwiperCore, {Autoplay} from 'swiper';
import {Button} from "@material-ui/core";

export const FeaturedServices = () => {

    SwiperCore.use([Autoplay]);

    const {handleClickService} = useServices();

    const category = useSelector(state => state?.ui?.current?.services?.category);
    const featured = useSelector(state => state?.services?.services?.featured);
    const mode = useSelector(state => state?.ui?.mode);

    const [loop, setLoop] = useState();

    useEffect(() => {
        setLoop(category && mode ? category?.featured?.length > 1 : featured?.length > 1)
    }, [featured, category])

    return (
        <Container mode={mode}>
            {category && category?.featured?.length > 0 || featured?.length > 0 && !category &&
            <Title mode={mode}>Te recomendamos</Title>
            }
            <Swiper
                spaceBetween={10}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                pagination={{"clickable": true}}
                slidesPerView={mode ? (loop ? 1.25 : 1) : (loop ? 2 : 1)}
                loop={loop}
            >
                {category && mode
                    ? (
                        category?.featured?.map((service, i) => {
                            return (
                                <SwiperSlide
                                    onClick={() => handleClickService(service)}
                                    key={i}
                                    style={{
                                        width: featured?.length === 1 ? '100vw' : '80vw',
                                        cursor: 'pointer !important'
                                    }}
                                >
                                    <Image img={service.featured_picture_url}/>
                                    <Shadow>
                                        <ContentContainer>
                                            <Content>{service?.description}</Content>
                                        </ContentContainer>
                                        <Logo src={service?.featured_brand_logo_url}/>
                                    </Shadow>
                                </SwiperSlide>
                            )
                        })
                    ) : (
                        featured?.map((service, i) => {
                            return (
                                <SwiperSlide
                                    key={i} style={{width: featured?.length === 1 ? '100vw' : '80vw'}}
                                    onClick={() => handleClickService(service)}
                                >
                                    <Button style={{
                                        borderRadius: 8,
                                        width: '100%',
                                        fontFamily: 'Visby',
                                        padding: 0,
                                        textAlign: 'left',
                                        textTransform: 'none',
                                        lineHeight: 'normal',
                                        fontSize: 'inherit',
                                        fontWeight: 400
                                    }}>
                                        <Image img={service.featured_picture_url}/>
                                        <Shadow>
                                            <ContentContainer>
                                                <Content>{service?.description}</Content>
                                            </ContentContainer>
                                            <Logo src={service?.featured_brand_logo_url}/>
                                        </Shadow>
                                    </Button>
                                </SwiperSlide>
                            )
                        })
                    )
                }
            </Swiper>
        </Container>
    )
}
