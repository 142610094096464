import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paper': {
            margin: 16,
            borderRadius: 12,
        }
    },
    option: {
        textTransform: 'none',
        color: '#197D8F',
        fontFamily: 'Visby'
    },
}));
