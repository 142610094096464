import React, {useEffect, useState} from 'react';
import {
    BrandContainer,
    BrandLogo,
    BrandName,
    ButtonContent,
    Content,
    ContentContainer,
    ContentDescription,
    Description,
    DetailsContainer,
    Discount,
    DiscountDetails,
    Header,
    Name,
    OldPrice,
    Picture,
    Price,
    Title
} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {BackButton, BackIcon, Loading, ShareButton, ShareIcon} from "../../../../styles";
import {useStyles} from "./materialStyles";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import {useServices} from "../../../../hooks/useServices";
import {CircularProgress} from "@material-ui/core";
import useQuery from "../../../../hooks/useQuery";
import {setCurrentService} from "../../../../actions/ui";
import {Marketplace} from "../../../desktop/marketplace/Marketplace";
import {Helmet} from "react-helmet";
import squareIcon from "../../../../img/square_icon.png";
import {helmetJsonLdProp} from "react-schemaorg";

export const Details = () => {

    const query = useQuery();

    const {
        groupServicesByCategory,
        handleClickConsultService
    } = useServices();

    const classes = useStyles();
    const history = useHistory();
    const service = useSelector(state => state?.ui?.current?.services?.service);

    const dispatch = useDispatch();
    const {pathname} = useLocation();

    const [loop, setLoop] = useState();
    const loading = useSelector(state => state?.services?.loading);
    const mode = useSelector(state => state?.ui?.mode);
    const services = useSelector(state => state?.services?.services);
    const currentService = useSelector(state => state?.ui?.current?.services?.service);
    const cityConfig = useSelector(state => state?.ui?.cityConfig);

    const urlParams = {
        city_id: Number(query.get('city_id')),
        category: {
            id: Number(pathname?.split("-")[0]?.replace('/', ''))
        },
        service: {
            id: Number(pathname?.split("/")[2].split("-")[0]?.replace('/', ''))
        }
    }

    useEffect(() => {
        if (service) {
            setLoop(service?.pictures?.length !== 1)
        }
    }, [service])

    useEffect(() => {
        if (!loading && !currentService) {

            const servicesGrouped = groupServicesByCategory(services?.services)
            const currentCategory = servicesGrouped?.filter(group => group?.groupId === urlParams?.category?.id && group?.cityId === urlParams?.city_id)[0];
            const currentService = currentCategory?.services?.filter(service => service.id === urlParams?.service?.id)[0]

            if (currentService) {
                dispatch(setCurrentService(currentService))
            } else {
                if (cityConfig) {
                    history.push(`/?city_id=${cityConfig.city_id}`)
                } else {
                    history.push('/')
                }
            }

        }

    }, [loading])

    const handleBack = () => {
        history.goBack();
    }

    const handleShare = () => {
    }

    const servicePrice = service?.discount_percentage_value && Number(service?.discount_percentage_value) !== 0 ? service?.price - ((service?.discount_percentage_value / 100) * service?.price) : service?.price
    const priceWithInterest = servicePrice + ((service?.interest_fees_value / 100) * servicePrice);

    return (
        <>
            {mode
                ? (loading
                        ? (<Loading><CircularProgress style={{color: '#197d8f'}}/></Loading>)
                        : (<DetailsContainer>
                            <Header>
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    loop={loop}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: 0
                                    }}
                                    autoplay={{
                                        "delay": 2500,
                                        "disableOnInteraction": false
                                    }}
                                    pagination={{
                                        "clickable": true
                                    }}
                                    className="mySwiper"
                                >
                                    {service?.pictures?.map((picture, i) => {
                                        return (
                                            <SwiperSlide
                                                key={i}
                                                className={classes.slide}
                                            >
                                                <Picture width={'100%'} height={'100%'} src={picture?.picture_url}/>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>

                                <BackButton
                                    className={classes.headerButton}
                                    onClick={handleBack}
                                    style={{
                                        top: 0,
                                        left: 0
                                    }}
                                >
                                    <BackIcon className={classes.headerIcon}/>
                                </BackButton>

                                <ShareButton
                                    className={classes.headerButton}
                                    onClick={handleShare}
                                    style={{
                                        top: 0,
                                        right: 0,
                                        display: 'none'
                                    }}
                                >
                                    <ShareIcon className={classes.headerIcon}/>
                                </ShareButton>
                            </Header>
                            <ContentContainer>
                                <Content>
                                    <BrandContainer style={{marginTop: 20}}>
                                        {service?.group?.logo_url && <BrandLogo src={service?.group?.logo_url}/>}
                                        {service?.group?.name && <BrandName>{service?.group?.name}</BrandName>}
                                    </BrandContainer>
                                    <Name>{service?.name}</Name>
                                    <Description>{service?.description}</Description>

                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '20px 0 14px 0',
                                    }}>
                                        <Picture
                                            width={'166px'}
                                            height={'166px'}
                                            src={service?.icon_url}
                                            borderRadius={'8px'}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#198F4C',
                                            marginBottom: 5
                                        }}
                                    >
                                        {service?.discount_percentage_value && Number(service?.discount_percentage_value) !== 0
                                            ? (
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: '#198F4C',
                                                    marginBottom: 5
                                                }}>
                                                    <Discount>-{service?.discount_percentage_value.toString()?.split(".")[0]}%</Discount>
                                                    <Price>${servicePrice?.toLocaleString(navigator.language, {maximumFractionDigits: 2})}</Price>
                                                    <OldPrice>${service?.price?.toLocaleString(navigator.language, {maximumFractionDigits: 2})}</OldPrice>
                                                </div>
                                            ) : (
                                                <>
                                                    {servicePrice &&
                                                        <Price>Desde ${servicePrice?.toLocaleString(navigator.language, {maximumFractionDigits: 2})}</Price>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>

                                    {service?.amount_fees > 1 &&
                                    <DiscountDetails
                                        color={service?.interest_free_fees}
                                    >

                                        {service?.amount_fees} cuotas de
                                        ${(priceWithInterest / service?.amount_fees)?.toLocaleString(navigator.language, {maximumFractionDigits: 2})} {service?.interest_free_fees && ' sin interés'}

                                    </DiscountDetails>}

                                    <div>
                                        <Title>Descripción</Title>
                                        <ContentDescription mode={mode}
                                                            dangerouslySetInnerHTML={{__html: service?.content}}/>
                                    </div>
                                </Content>
                            </ContentContainer>
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    position: 'absolute',
                                    bottom: 0,
                                    zIndex: 1,
                                    width: '100%'
                                }}
                            >
                                <div
                                    id='button'
                                    style={{
                                        width: '100%', display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <ButtonContent
                                        className={classes.buttonSticky}
                                        onClick={() => handleClickConsultService(service, 'listing')}
                                        style={{
                                            backgroundColor: service?.actions?.color
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Picture style={{marginRight: 10}} width={'24px'} height={'24px'}
                                                     src={service?.actions?.icon}/>
                                            <p style={{margin: 0}}>
                                                {service?.actions?.text}
                                            </p>
                                        </div>
                                    </ButtonContent>
                                </div>
                            </div>
                            <Helmet
                                script={[
                                    helmetJsonLdProp({
                                        "@context": "https://schema.org/",
                                        "@type": "Product",
                                        "name": service?.name,
                                        "image": service?.icon_url,
                                        "slogan": service?.description,
                                        "offers": {
                                            "@type": "Offer",
                                            "priceCurrency": "ARS",
                                            "price": servicePrice?.toLocaleString(navigator.language, {maximumFractionDigits: 2})
                                        },
                                        "description": service?.content,
                                    }),
                                ]}
                            >
                                <title>{`Tienda Ualabee - ${service?.name?.replace('&', 'y')}`}</title>
                                <meta name='description' content={`${service?.description}`}/>
                                <link href={squareIcon} rel="icon"/>
                                <link href={squareIcon} rel="apple-touch-icon"/>
                                <meta content={`Tienda Ualabee - ${service?.name?.replace('&', 'y')}`}
                                      property="og:title"/>
                                <meta content={`${service?.description}`} property="og:description"/>
                                <meta content="website" property="og:type"/>
                                <meta content="Tienda Ualabee" property="og:site_name"/>
                                <meta content="es_ES" property="og:locale"/>
                                <meta content="en_GB" property="og:locale:alternate"/>
                                <meta content="en_US" property="og:locale:alternate"/>
                                <meta
                                    content="Recarga de saldo, reserva de pasajes, seguro de pertenencias personales, movilidad y tecnología, tienda ualabee, tienda ualabe, tienda gualabe, tienda walabe, tienda ualabi, tienda ualaby, tienda walabi, tienda wallabi, tienda wallaby, tienda wallabee, tienda walaby, tienda walabee, tienda gualabi, tienda gualaby, tienda gualabee, tienda guallabi, tienda guallabee, tienda ualavee, tienda ualave, tienda gualave, tienda walave, tienda ualavi, tienda ualavy, tienda walavi, tienda wallavi, tienda wallavy, tienda wallavee, tienda walavy, tienda walavee, tienda gualavi, tienda gualavy, tienda gualavee, tienda guallavi, tienda guallavee"
                                    name="keywords"/>
                            </Helmet>
                        </DetailsContainer>)
                ) : (
                    <Marketplace/>
                )
            }

        </>
    )
}
