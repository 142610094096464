import {types} from "../types/types";

const initialState = {
    forceRedirect: true,
    loading: true,
    search: {
        value: ''
    }
}

export const servicesReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.services:
            return {
                ...state,
                services: action.payload,
            }
        case types.loading:
            return {
                ...state,
                loading: action.payload,
            }
        case types.searchValue:
            return {
                ...state,
                search: {
                    ...state.search,
                    value: action.payload
                }
            }
        case types.forceRedirect:
            return {
                ...state,
                forceRedirect: action.payload
            }
        default:
            return state;
    }

}
