import styled from "styled-components/macro";

export const ComponentContainer = styled.div`width: 100%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;  
`

export const Container = styled.div`    
    width: 60%;
`
