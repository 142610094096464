export const getLocalVariantId = () => {

    const variantId = JSON.parse(localStorage.getItem('variant_id' || 'null'))

    if (isNaN(parseInt(variantId))) {
        localStorage.setItem('variant_id', '1');
        return '1'
    }

    return variantId

}