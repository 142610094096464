import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dialog, Slide} from "@material-ui/core";
import {setCurrentCard, setOpenDeleteDialog, setUserCards as setCurrentUserCards} from "../../../../actions/recharge";
import {Buttons, Cancel, Container, Delete, Options, Title} from "./styles";
import {useStyles} from "./material";
import {setUserCards} from "../../../../db/movypay/localData";
import {useHistory} from "react-router-dom";
import {logEvent} from "../../../../firebase/firebase-config";
import {deleteUserRechargeCard} from "../../../../db/movypay/deleteUserRechargeCard";
import useQuery from "../../../../hooks/useQuery";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteCard = () => {

    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();

    const uid = useSelector(state => state?.auth?.uid);
    const {user_cards: cards} = useSelector(state => state?.recharge);
    const open = useSelector(state => state?.recharge?.components?.delete_card);
    const currentCard = useSelector(state => state?.recharge?.bus_card);
    const cityConfig = useSelector(state => state?.ui?.cityConfig);


    const handleClose = () => {
        dispatch(setOpenDeleteDialog(!open));
    }

    const handleDelete = async () => {

        await deleteUserRechargeCard( {
            id: Number(currentCard?.id)
        });

        logEvent('recharge_card_remove', {
            card_id: currentCard?.id, // Int
            card_name: currentCard?.name, // String
            card_company_name: currentCard?.recharge_services?.name, // String
            card_number: currentCard?.card_ref_id, // String
            user_id: uid, // String
            city_id: cityConfig?.city_id // Int
        })

        const current = cards?.filter(card => card?.name !== currentCard?.name);

        setUserCards(current);

        dispatch(setCurrentCard({name: '', number: ''}))
        dispatch(setCurrentUserCards(current));

        handleClose();

        if (current?.length === 0) {
            history.push(`/card-recharge/card-data?custom_token=${query.get('custom_token') || null}`)
        }

    }

    return (
        <Dialog
            open={open}
            className={classes.dialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="delete-card"
        >
            <Container>
                <Title>¿Eliminar tarjeta?</Title>
                <Options>
                    <Buttons>
                        <Cancel
                            onClick={handleClose}
                            className={classes.option}
                        >
                            Cancelar
                        </Cancel>
                        <Delete
                            onClick={handleDelete}
                            className={classes.option}
                        >
                            Eliminar
                        </Delete>
                    </Buttons>
                </Options>
            </Container>
        </Dialog>
    )

}
