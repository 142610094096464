import styled from "styled-components/macro";
import {Button} from "@material-ui/core";

export const Container = styled.div` 
    width: calc(100vw - 36px); 
`;

export const Title = styled.p`  
    margin: 0 0 23px 0;
    font-size: 16px;
    padding: 16px 0 0 16px; 
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 6px 16px;
    width: 190px;
`;

export const Cancel = styled(Button)` 
`;

export const Delete = styled(Button)` 
`;


export const Options = styled.div` 
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
