import {types} from "../types/types";
import {getConfig} from "../db/getConfig";
import {getCityByLatLng} from "../db/getCityByLatLng";
import {setAllServices, setLoading} from "./services";
import {getServices} from "../db/getServices";
import {getServiceTypes} from "../db/getServiceTypes";
import {setLocalCityConfig} from "../db/setLocalCityConfig";
import {getAllCountries} from "../db/getAllCountries";
import {setCountries} from "./db";
import {getRechargeServices} from "../db/movypay/getRechargeServices";
import {setCompanies, setCurrentCard, setCurrentCompany, setUserCards} from "./recharge";
import {getUserRechargeCards} from "../db/movypay/getUserRechargeCards";
import {setLocalCityId} from "../db/localData";

/**
 * setCurrentServicesByCategory()
 * @param {Object} data
 * @returns {{payload, type: string}}
 */

export const setCurrentServicesByCategory = (data) => ({
    type: types.servicesByCategory,
    payload: data
});

/**
 * setCurrentService()
 * @param data
 * @returns {{payload, type: string}}
 */

export const setCurrentService = (data) => ({
    type: types.serviceSelected,
    payload: data
});

/**
 * setCityConfig()
 * @param cityConfig
 * @returns {{payload, type: string}}
 */

export const setCityConfig = (cityConfig) => ({
    type: types.cityConfig,
    payload: cityConfig
});

/**
 * setOpenSearch()
 * @param {Boolean} boolean
 * @returns {{payload, type: string}}
 */

export const setOpenSearch = (boolean) => ({
    type: types.openSearch,
    payload: boolean
});

export const setOpenCountries = (boolean) => ({
    type: types.openCountries,
    payload: boolean
})

export const setUserPosition = (position) => ({
    type: types.userPosition,
    payload: position
})


export const setAppConfiguration = () => {

    return async (dispatch) => {

        const query = new URLSearchParams(window.location.search);

        try {
            const currentVariantId = JSON.parse(localStorage.getItem('variant_id') || 'null')

            if (!currentVariantId || isNaN(parseInt(currentVariantId))) {
                localStorage.setItem('variant_id', '1');
            }
        } catch (e) {
            localStorage.setItem('variant_id', '1');
            console.log(e)
        }

        if (query.has('variant_id') && !isNaN(parseInt(query.get('variant_id')))) {
            localStorage.setItem('variant_id', query.get('variant_id'));
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {

                const userPosition = {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude,
                }

                if (query.get('city_id') || query.get('ci')) { // current 'city_id' value from url query params

                    const cityConfig = await getConfig(query.get('city_id') || query.get('ci'));

                    setLocalCityConfig(cityConfig);
                    setLocalCityId(query.get('city_id') || query.get('ci'));

                    const services = await getServices(cityConfig?.city_id);
                    const serviceTypes = await getServiceTypes();

                    const allServices = {
                        news: services?.new_services,
                        featured: services?.featured_services,
                        services: services?.services,
                        types: serviceTypes
                    }

                    dispatch(setAllServices(allServices));
                    dispatch(setCityConfig(cityConfig));
                    dispatch(setLoading(false));

                } else {

                    const cityConfig = await getCityByLatLng(userPosition);
                    setLocalCityConfig(cityConfig);

                    const services = await getServices(cityConfig?.city_id);
                    const serviceTypes = await getServiceTypes();

                    const allServices = {
                        news: services?.new_services,
                        featured: services?.featured_services,
                        services: services?.services,
                        types: serviceTypes
                    }

                    dispatch(setAllServices(allServices))
                    dispatch(setUserPosition(userPosition));
                    dispatch(setCityConfig(cityConfig));
                    dispatch(setLoading(false));
                }

            },
            async () => {

                if (query.get('city_id') || query.get('ci')) { // current 'city_id' value from url query params

                    const cityConfig = await getConfig(query.get('city_id') || query.get('ci'))

                    setLocalCityId(query.get('city_id') || query.get('ci'))
                    setLocalCityConfig(cityConfig);

                    const services = await getServices(cityConfig?.city_id);
                    const serviceTypes = await getServiceTypes();

                    const allServices = {
                        news: services?.new_services,
                        featured: services?.featured_services,
                        services: services?.services,
                        types: serviceTypes
                    }

                    dispatch(setAllServices(allServices));
                    dispatch(setCityConfig(cityConfig));
                    dispatch(setLoading(false));

                } else {

                    const countries = await getAllCountries();
                    dispatch(setCountries(countries));
                    dispatch(setOpenCountries(true));

                }

            });


        if (query.get('recharge_service_id') || query.get('rsi')) {

            const rechargeServiceId = parseInt(query.get('rsi') || query.get('recharge_service_id'))
            const companies = await getRechargeServices();
            const currentCompany = companies?.find(company => company?.id === rechargeServiceId);
            dispatch(setCurrentCompany(currentCompany));
            dispatch(setCompanies(companies));

            const userCards = await getUserRechargeCards(rechargeServiceId);
            dispatch(setUserCards(userCards));

            if (query.get('card_id') || query.get('urci')) {
                const currentUserCard = userCards?.find(card => card?.id === parseInt(query.get('card_id')) || card?.id === parseInt(query.get('urci')));
                dispatch(setCurrentCard(currentUserCard));
            }

        }


    }

}

export const setOpenCities = (data) => ({
    type: types.openCities,
    payload: data
})
