import React from 'react';
import logo from '../../../img/movypay/logo-movypay.png'
import {Container, Logo, Text} from "./styles";

export const PoweredBy = () => {
    return (
        <Container>
            <Text> Powered by </Text>
            <Logo alt='Movypay logo' src={logo}/>
        </Container>
    )
}