import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    result: {
        textTransform: 'none',
        padding: 0,
        marginBottom: 10,
        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontFamily: 'Visby',
            lineHeight: 'normal'
        }
    },
    searchButton: {
        display: 'none',
        backgroundColor: '#BCBEC0',
        marginLeft: 18,
        '&:hover': {
            backgroundColor: '#BCBEC0',
        },
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Visby'
    },
}));
