import { types } from "../types/types";

export const authReducer = (state = {}, action) => {

    switch (action.type) {
        case types.user:
            return {
                uid: action.payload.uid,
                token: action.payload.token,
                user: action.payload.user
            }
        case types.cityId:
            return {
                city_id: action.payload,
            }
        default:
            return state;
    }

}
