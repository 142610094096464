import styled from "styled-components/macro";
import {Button} from "@material-ui/core";
import {CheckCircleRounded, ErrorRounded} from "@material-ui/icons";

export const Container = styled.div` 
    max-height: 100vh; 
    overflow: hidden scroll;
    position: relative;  
    height: 100vh;
    max-height: 100vh;
    overflow: hidden scroll;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100vw;
`;

export const InformationContainer = styled.div`
    background-color: #FAFAFA;
    width: calc(100% - 32px);
    position: relative;
    border-radius: 12px; 
    margin: 0 16px 32px 16px;
`;

export const Info = styled.p`  
    margin: 0;
    padding: 16px 16px 19px 16px;
    font-size: 12px;
    text-align: center;
    color: #3D3E3F;
`;

export const ViewTerminals = styled(Button)`   
`;

export const CheckIcon = styled(CheckCircleRounded)`   
`;

export const ErrorIcon = styled(ErrorRounded)`   
`;

export const IconContainer = styled.div`   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 36px 0 32px 0;
`;

export const Title = styled.h1`
    font-size: 16px;
    color: #3D3E3F;
    margin: 0;
`;

export const Items = styled.div` 
    margin: 0 16px 40px 16px;
`;

export const Item = styled.div` 
    margin: 0 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


export const ItemName = styled.p` 
    margin: 0;
    font-size: 14px;
    color: #3D3E3F;
`;

export const ItemContent = styled.p` 
    margin: 0;
    font-size: 14px;
    color: ${props => props?.color};
    margin-right: 8px;
`;

export const ItemImage = styled.img`  
    width: 25px;
    height: 14px; 
`;

export const CardType = styled.div`
    display: flex;
    align-items: center;
`;

export const DownloadPayment = styled(Button)` 
`;

export const PaymentIcon = styled.img` 
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

export const SelectAnotherCard = styled.a`
    color: white;
    width: calc(100% - 32px);
    height: 38px;
    margin: 0 16px;
    display: flex;
    border-radius: 14px;
    margin-bottom: 14px;
    text-transform: none;
    justify-content: center;
    align-items: center;
    color: #197D8F;
    text-decoration: none;
    background-color: #fafafa;
`;
