import {types} from "../types/types";
import Visa from '../img/movypay/debit_cards/visa.png';
import Maestro from '../img/movypay/debit_cards/maestro.png';
import Mastercard from '../img/movypay/debit_cards/mastercard.png';
import Cabal from '../img/movypay/debit_cards/cabal.png';
import Amex from '../img/movypay/debit_cards/amex.png';
import Naranja from '../img/movypay/debit_cards/naranja.png';

export const debitTypes = [
    {
        id: 1,
        name: 'Visa Crédito',
        icon: Visa
    },
    {
        id: 2,
        name: 'Mastercard Crédito',
        icon: Mastercard
    },
    {
        id: 3,
        name: 'Cabal Crédito',
        icon: Cabal
    },
    {
        id: 4,
        name: 'Naranja Crédito',
        icon: Naranja
    },
    {
        id: 5,
        name: 'Amex Crédito',
        icon: Amex
    },
    {
        id: 6,
        name: 'Visa Débito',
        icon: Visa
    },
    {
        id: 7,
        name: 'Maestro Débito',
        icon: Maestro
    },
    {
        id: 8,
        name: 'Mastercard Débito',
        icon: Mastercard
    },
    {
        id: 9,
        name: 'Cabal Débito',
        icon: Cabal
    },
]

/**
 * getRechargeError()
 * @param {Number} error - Error obtained from the url (query param)
 * @returns {string} - Associated error message
 */

export const getRechargeError = (error) => {
    switch (error) {
        case 931:
            return 'Browser no soportado'
        case 932:
            return 'Formulario de pago inválido'
        case 933:
            return 'Monto inválido para comercio'
        case 934:
            return 'No hemos podido continuar con la operación. El pago no fue ejecutado.'
        default:
            return 'No hemos podido continuar con la operación. Por favor, revise si los datos ingresados son correctos.'

    }
};

/**
 * setCompanies()
 * @param companies
 * @returns {{payload, type: string}}
 */

export const setCompanies = (companies) => ({
    type: types.companies,
    payload: companies
});

/**
 * setCurrentCompany()
 * @param company
 * @returns {{payload, type: string}}
 */

export const setCurrentCompany = (company) => ({
    type: types.company,
    payload: company
});

/**
 * setCurrentBusName()
 * @param card
 * @returns {{payload, type: string}}
 */

export const setCurrentBusName = (card) => ({
    type: types.bus_name,
    payload: card
});

/**
 * setCurrentBusNumber()
 * @param number
 * @returns {{payload, type: string}}
 */

export const setCurrentBusNumber = (number) => ({
    type: types.bus_number,
    payload: number
});

/**
 * setCurrentCard()
 * @param card - Sets current bus card
 * @returns {{payload, type: string}}
 */

export const setCurrentCard = (card) => ({
    type: types.card,
    payload: card
});

/**
 * setUserCards();
 * @param cards
 * @returns {{payload, type: string}}
 */

export const setUserCards = (cards) => ({
    type: types.cards,
    payload: cards
});

/**
 * setOpenDeleteDialog()
 * @param boolean - True open the dialog and false close it
 * @returns {{payload, type: string}}
 */

export const setOpenDeleteDialog = (boolean) => ({
    type: types.delete_card,
    payload: boolean
});

/**
 * setOpenEditDialog()
 * @param boolean
 * @returns {{payload, type: string}}
 */

export const setOpenEditDialog = (boolean) => ({
    type: types.edit_card,
    payload: boolean
});

/**
 * setRechargeTerminals()
 * @param terminals
 * @returns {{payload, type: string}}
 */

export const setRechargeTerminals = (terminals) => ({
    type: types.terminals,
    payload: terminals
});


/*

export const setCurrentDebitDate = (name) => ({
    type: types.debit_date,
    payload: name
});

export const setCurrentDebitNumber = (number) => ({
    type: types.debit_number,
    payload: number
});

export const setCurrentDebitCard = (card) => ({
    type: types.debit_card,
    payload: card
});


export const getDebitCardType = (numbers) => { // numbers => first four debit card numbers

    return (dispatch, getState) => {

        const {recharge} = getState();

        const name = creditCardType(numbers)[0]?.niceType

        const type = {
            icon: debitTypes?.filter((type) => type?.name === name)[0]?.icon,
            ...creditCardType(numbers)[0]
        }

        dispatch(setCurrentDebitCard({...recharge?.debit_card, type}))

    }
};

getDebitCardType.propTypes = {
    result: PropTypes.string.isRequired,
};

*/