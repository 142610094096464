import styled from "styled-components/macro";
import {HistoryRounded} from "@material-ui/icons";

export const ComponentContainer = styled.div`   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const MessageIcon = styled(HistoryRounded)`
    color: #A6A9AC; 
    font-size: 48px; 
    margin-bottom: 16px;
`;

export const MessageText = styled.h4`
    font-family: 'Visby';
    color: #A6A9AC;
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    font-weight: regular;
    text-align: center;
`
