import {types} from "../types/types";
import PropTypes from "prop-types";

/**
 * setAllServices() - save all marketplace services
 * @param {Array} services
 * @returns {{payload, type: string}}
 */

export const setAllServices = (services) => ({
    type: types.services,
    payload: services
})

/**
 * setLoading()
 * @param {Boolean} boolean
 * @returns {{payload, type: string}}
 */

export const setLoading = (boolean) => ({
    type: types.loading,
    payload: boolean
})

/**
 * setSearchValue()
 * @param {String} value - search input value
 * @returns {{payload, type: string}}
 */

export const setSearchValue = (value) => ({
    type: types.searchValue,
    payload: value
})

/**
 * @description Sets forceRedirect value in redux store.
 * If the forceRedirect value is true force a redirect click on the online recharge card
 * @param value
 * @returns {{payload, type: string}}
 */

export const setForceRedirect = (value) => ({
    type: types.forceRedirect,
    payload: value
});

setForceRedirect.prototype = {
    value: PropTypes.bool.isRequired,
}
