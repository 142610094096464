import {types} from "../types/types";

export const rechargeReducer = (state = {
    bus_card: {
        name: '',
        number: ''
    },
    debit_card: {
        date: '',
        number: ''
    },
    components: {
        delete_card: false,
        edit_card: false,
    },
    card: {
        name: '',
        number: ''
    }
}, action) => {

    switch (action.type) {
        case types.companies:
            return {
                ...state,
                companies: action.payload,
            }
        case types.company:
            return {
                ...state,
                company: action.payload,
            }
        case types.bus_number:
            return {
                ...state,
                bus_card: {
                    ...state.bus_card,
                    number: action.payload,
                }
            }
        case types.bus_name:
            return {
                ...state,
                bus_card: {
                    ...state.bus_card,
                    name: action.payload,
                }
            }
        case types.card:
            return {
                ...state,
                bus_card: action.payload
            }
        case types.amounts:
            return {
                ...state,
                amounts: action.payload
            }
        case types.cards:
            return {
                ...state,
                user_cards: action.payload
            }
        case types.delete_card:
            return {
                ...state,
                components: {
                    ...state.components,
                    delete_card: action.payload
                }
            }

        case types.debit_date:
            return {
                ...state,
                debit_card: {
                    ...state.debit_card,
                    date: action.payload
                }
            }
        case types.debit_number:
            return {
                ...state,
                debit_card: {
                    ...state.debit_card,
                    number: action.payload
                }
            }
        case types.debit_card:
            return {
                ...state,
                debit_card: action.payload
            }
        case types.edit_card:
            return {
                ...state,
                components: {
                    ...state.components,
                    edit_card: action.payload
                }
            }
        case types.terminals:
            return {
                ...state,
                terminals: action.payload
            }
        default:
            return state;
    }

}
