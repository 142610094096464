import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    serviceTypeMobile: {
        maxWidth: '33.3%',
        minWidth: '33.3%',
        '& .MuiButton-label': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            flexDirection: 'column',
            textTransform: 'none',
            fontFamily: 'Visby'
        },
        borderRadius: 8,
        padding: 0,
    },
    serviceTypeDesktop: {
        '& .MuiButton-label': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textTransform: 'none',
            fontFamily: 'Visby'
        },
        borderRadius: 8,
    }
}));

